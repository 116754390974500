import React, { useState } from "react";
// import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";
import axcess from "../images/0.0 Repeating Modules/axcess.jpg";
import chsch from "../images/0.0 Repeating Modules/chsch.jpg";
import emoney from "../images/0.0 Repeating Modules/emoney.jpg";
import qb from "../images/0.0 Repeating Modules/qb.jpg";
import taxDome from "../images/0.0 Repeating Modules/TD.png";
import smallPlus from "../images/0.0 Repeating Modules/Plus icon small.svg";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Client Portal | DeBlanc, Murphy & Murphy"
        description="View DeBlanc, Murphy & Murphy's client portal here. We are a top CPA firm in the Washington DC Metro Area."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section>
        <div className="container">
          <div className="pb-20 pt-10 text-center lg:pb-32 lg:pt-16">
            <header className="mb-12 text-center lg:mb-16">
              <h1>Client Portal</h1>
            </header>
            <div className="mb-[50px] flex w-full items-center justify-center text-center">
              <img
                src={smallPlus}
                width={45}
                alt="Small plus sign"
                className="mx-auto md:mx-0"
              />
            </div>

            <div className="grid gap-y-10 lg:grid-cols-4 lg:gap-x-8 lg:px-26">
              <PortalItem
                image={emoney}
                label="e-money"
                url="https://wealth.emaplan.com/ema/ria/murphycpallc"
                buttonLabel="Log in"
                imageAlt="Small plus sign"
              />
              <PortalItem
                image={taxDome}
                label="TaxDome"
                url="https://deblancmurphy.taxdome.com/login"
                buttonLabel="Log in"
                imageAlt="Tax Dome sign"
              />
              <PortalItem
                image={qb}
                label="quickbooks Online"
                url="https://app.qbo.intuit.com/app/login?loadCustomerAssistanceAssets=us&product=qbOnline"
                buttonLabel="Log in"
                imageAlt="Small plus sign"
              />
              <PortalItem
                image={chsch}
                label="Charles Schwab"
                url="https://client.schwab.com/Login/SignOn/CustomerCenterLogin.aspx"
                buttonLabel="Log in"
                imageAlt="Charles Schwab sign"
              />
              {/* <PortalItem
                image={axcess}
                label="CCH Axcess"
                url="https://www.clientaxcess.com/#/user/login"
                buttonLabel="Log in"
                imageAlt="CCH Axcess sign"
              /> */}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

const PortalItem = ({ image, imageAlt, label, url, buttonLabel }) => {
  return (
    <div className="relative mx-auto max-w-[247px] rounded-3xl border border-primary-500 bg-gray-800 px-9 pb-[30px] pt-[30px] lg:w-full lg:max-w-none lg:pb-10">
      <div className="heading-three flex items-center justify-center px-5 text-center font-normal text-primary-500">
        <img
          src={image}
          width={200}
          alt={imageAlt}
          className="mx-auto w-[75px] justify-center md:mx-0 lg:w-full"
        />
      </div>

      <div className="mb-3.5 h-[45px] text-sm font-light uppercase tracking-wide">
        {label}
      </div>

      <div className=" lg:bottom-12 lg:left-10 lg:right-10">
        <a href={url} target="_Blank">
          <ButtonSolid
            text={buttonLabel}
            className="lg:min-h-[39px] lg:min-w-[124px]"
          />
        </a>
      </div>
    </div>
  );
};
// export const query = graphql`
//   {
//     openGraphImage: file(
//       relativePath: { eq: "open-graph/facebook/Global.jpg" }
//     ) {
//       publicURL
//     }
//     twitterOpenGraphImage: file(
//       relativePath: { eq: "open-graph/twitter/Global.jpg" }
//     ) {
//       publicURL
//     }
//   }
// `;

export default Page;
